import { useEffect, useMemo, useRef } from 'react'

import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import mixpanel from 'mixpanel-browser'
import Head from 'next/head'
import { MAP_LANDING, PAGE_UNLOADED } from '@avalanche-canada/constants/products/mixpanel'

import NonSSRWrapper from 'components/NonSSRWrapper'
import { Provider as AuthProvider, useAuth } from 'contexts/authentication'
import * as Internationalization from 'contexts/internationalization'
import { Script as Analytics, useService } from 'services/analytics'
import {
    useMapHistory,
    useSetForecastHistory,
    useForecastId,
    useSetForecastId,
    useForecastHistory,
} from 'stores/MixpanelHistoryStore'
import { useSetUpdateAvailable } from 'stores/UpdateStore'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import { useDangerRatingModalStore } from 'stores/DangerRatingModalStore'
/* Our Custom CSS */
import '../../styles/globals.css'
import '../../styles/react-spring-bottom-sheet.css'
import '../../styles/table.css'

/* Theme variables */
import '../theme/variables.css'

import 'react-swipeable-list/dist/styles.css'
import { DangerRatingTable } from 'layouts/products/forecast/DangerRatingTable/DangerRatingTable'

export default function App({ Component, pageProps }) {
    useService()

    const { user } = useAuth()

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
    const setUpdateAvailable = useSetUpdateAvailable()
    const mapHistory = useMapHistory()
    const forecastHistory = useForecastHistory()
    const setForecastHistory = useSetForecastHistory()
    const forecastId = useForecastId()
    const setForecastId = useSetForecastId()
    const sendTrackEvent = useSendTrackEvent()
    const { isOpen, close } = useDangerRatingModalStore()

    const trackingTimeoutRef = useRef(null)

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode]
    )

    useEffect(() => {
        if (typeof window !== 'undefined' && 'serviceWorker' in navigator && window.workbox !== undefined) {
            const wb = window.workbox
            // add event listeners to handle any of PWA lifecycle event
            // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-window.Workbox#events
            // TODO: hide these once done dev
            // wb.addEventListener('installed', event => {
            //     console.log(`Event ${event.type} is triggered.`)
            //     console.log(event)
            // })

            // wb.addEventListener('controlling', event => {
            //     console.log(`Event ${event.type} is triggered.`)
            //     console.log(event)
            // })

            // wb.addEventListener('activated', event => {
            //     console.log(`Event ${event.type} is triggered.`)
            //     console.log(event)
            // })

            // A common UX pattern for progressive web apps is to show a banner when a service worker has updated and waiting to install.
            // NOTE: MUST set skipWaiting to false in next.config.js pwa object
            // https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
            const promptNewVersionAvailable = event => {
                // `event.wasWaitingBeforeRegister` will be false if this is the first time the updated service worker is waiting.
                // When `event.wasWaitingBeforeRegister` is true, a previously updated service worker is still waiting.
                // You may want to customize the UI prompt accordingly.
                wb.addEventListener('controlling', event => {
                    // See file history: we are removing the prompt and forcing an auto reload+update since this is what happens all the time anyway
                    setUpdateAvailable(true)
                    window.location.reload()
                })

                // Send a message to the waiting service worker, instructing it to activate.
                wb.messageSkipWaiting()
            }

            wb.addEventListener('waiting', promptNewVersionAvailable)

            // never forget to call register as auto register is turned off in next.config.js
            wb.register()
        }
    }, [])

    useEffect(() => {
        mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '', {
            // Enable these in DEV mode to see console entries for Mixpanel requests
            // debug: true,
            // ignore_dnt: true,
            track_pageview: true,
        })

        process.env.NEXT_PUBLIC_MIXPANEL_TOKEN && mixpanel.register({ platform: 'pwa' })

        // We can't use sendTrackEvent here because it's not available until mixpanel has been initialized
        mixpanel.track(MAP_LANDING, {
            user_id: user?.sub,
        })
    }, [])

    useEffect(() => {
        const handleBeforeUnload = () => {
            trackPageUnloaded()
        }

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                trackPageUnloaded()
            }
        }

        const trackPageUnloaded = () => {
            if (trackingTimeoutRef.current) {
                clearTimeout(trackingTimeoutRef.current)
            }

            trackingTimeoutRef.current = setTimeout(() => {
                sendTrackEvent(PAGE_UNLOADED, {
                    url: window.location.href,
                    mapHistory,
                })

                if (forecastHistory.length > 0) {
                    sendTrackEvent(FORECAST_CLOSED, {
                        forecastHistory,
                        forecastId,
                    })

                    setForecastHistory([])
                    setForecastId(null)
                }

                trackingTimeoutRef.current = null
            }, 300)
        }

        // Two event listeners, hopefully one will fire when they close the app.
        // The debounce (setTimout) will cancel one of the calls if they're both called as the app is closing.
        window.addEventListener('beforeunload', handleBeforeUnload)
        document.addEventListener('visibilitychange', handleVisibilityChange)
        window.addEventListener('pagehide', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
            document.removeEventListener('visibilitychange', handleVisibilityChange)
            window.removeEventListener('pagehide', handleBeforeUnload)
            if (trackingTimeoutRef.current) {
                clearTimeout(trackingTimeoutRef.current)
            }
        }
    }, [mapHistory, user?.sub])

    // TODO: translate below metadata

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"
                />
                <title>Avalanche Canada</title>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

                <meta name="description" content="Avalanche Canada Mobile App" />
                <meta property="og:title" content="Avalanche Canada Mobile App" />
                <meta
                    content="https://res.cloudinary.com/avalanche-ca/image/upload/bo_20px_solid_rgb:fff,c_pad,h_315,w_600/v1413919754/logos/avalanche_canada_left_quqmls.jpg"
                    property="og:image"
                ></meta>

                <link rel="manifest" href="/manifest.json" />
                <link rel="icon" type="image/png" href="https://avalanche.ca/png/logo72.png" sizes="72x72"></link>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <meta name="apple-mobile-web-app-status-bar" content="#fff" />
                <meta name="theme-color" content="#fff" media="(prefers-color-scheme: light)" />
                <meta name="theme-color" content="#000" media="(prefers-color-scheme: dark)" />
            </Head>
            <ThemeProvider theme={theme}>
                <Internationalization.Provider>
                    <AuthProvider>
                        <CssBaseline />
                        <div suppressHydrationWarning>
                            <NonSSRWrapper>
                                {/* Render the danger rating modal high in the component tree since it is used on both the main map and the forecast pages */}
                                <DangerRatingTable show={isOpen} close={close} />
                                {typeof window === 'undefined' ? null : <Component {...pageProps} />}
                            </NonSSRWrapper>
                        </div>
                    </AuthProvider>
                    <Analytics />
                </Internationalization.Provider>
            </ThemeProvider>
        </>
    )
}
