import { Modal, Box, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'

import { useTableDangerRatings } from 'hooks/useDangerRatings'
import { Row } from './Row'

import styles from './DangerRatingTable.module.css'

type DangerRatingTableProps = {
    show: boolean
    close: () => void
}

export const DangerRatingTable = ({ show, close }: DangerRatingTableProps) => {
    const rows = useTableDangerRatings()

    return (
        <Modal open={show} onClose={close}>
            {/* The data-body-scroll-lock-ignore is necessary to allow the modal to be scrollable (otherwise, the bottom sheet will prevent this) */}
            <Box className={styles.Wrapper} data-body-scroll-lock-ignore>
                <Box className={styles.Header}>
                    <h3 className={styles.Title}>
                        <FormattedMessage
                            defaultMessage="North American Public Avalanche Danger Scale"
                            description="Danger Ratings Table"
                        />
                    </h3>
                    <Box className={styles.CloseButton}>
                        <IconButton onClick={close} aria-label="close">
                            <Close />
                        </IconButton>
                    </Box>
                    <p className={styles.Subheader}>
                        <FormattedMessage
                            defaultMessage="Avalanche danger is determined by the likelihood, size, and distribution of avalanches. Safe backcountry travel requires training and experience. You control your risk by choosing when, where, and how you travel."
                            description="Danger Ratings Table"
                        />
                    </p>
                </Box>
                <Box className={styles.Grid}>
                    <Box className={styles.GridHeader}>
                        <Box className={styles.HeaderCell}>
                            <FormattedMessage defaultMessage="Danger Level" description="Danger Ratings Table" />
                        </Box>
                        <Box className={styles.HeaderCell}>
                            <FormattedMessage defaultMessage="Travel Advice" description="Danger Ratings Table" />
                        </Box>
                        <Box className={styles.HeaderCell}>
                            <FormattedMessage defaultMessage="Likelihood" description="Danger Ratings Table" />
                        </Box>
                        <Box className={styles.HeaderCell}>
                            <FormattedMessage
                                defaultMessage="Size and Distribution"
                                description="Danger Ratings Table"
                            />
                        </Box>
                    </Box>
                    <Box className={styles.GridBody}>
                        {rows.map(row => row.render && <Row key={row.rating} row={row} />)}
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}
