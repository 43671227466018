import { defineMessages } from 'react-intl'
import * as Ratings from '@avalanche-canada/constants/products/forecast/ratings'

export const TravelAdvices = defineMessages({
    [Ratings.EXTREME]: {
        description: 'Travel Advice message for EXTREME',
        defaultMessage: '<b>Extraordinarily dangerous avalanche conditions.</b> Avoid all avalanche terrain.',
        values: {
            b: chunks => <strong>{chunks}</strong>,
        },
    },
    [Ratings.HIGH]: {
        description: 'Travel Advice message for HIGH',
        defaultMessage: '<b>Very dangerous avalanche conditions.</b> Travel in avalanche terrain not recommended.',
        values: {
            b: chunks => <strong>{chunks}</strong>,
        },
    },
    [Ratings.CONSIDERABLE]: {
        description: 'Travel Advice message for CONSIDERABLE',
        defaultMessage:
            '<b>Dangerous avalanche conditions.</b> Careful snowpack evaluation, cautious route-finding, and conservative decision-making essential.',
        values: {
            b: chunks => <strong>{chunks}</strong>,
        },
    },
    [Ratings.MODERATE]: {
        description: 'Travel Advice message for MODERATE',
        defaultMessage:
            '<b>Heightened avalanche conditions on specific terrain features.</b> Evaluate snow and terrain carefully; identify features of concern.',
        values: {
            b: chunks => <strong>{chunks}</strong>,
        },
    },
    [Ratings.LOW]: {
        description: 'Travel Advice message for LOW',
        defaultMessage:
            '<b>Generally safe avalanche conditions.</b> Watch for unstable snow on isolated terrain features.',
        values: {
            b: chunks => <strong>{chunks}</strong>,
        },
    },
    [Ratings.NO_RATING]: {
        description: 'Travel Advice message for NO_RATING',
        defaultMessage:
            'This elevation band does not exist in the region, or there’s not yet enough snow at this elevation band to produce avalanches.',
    },
    [Ratings.NO_FORECAST]: {
        description: 'Travel Advice message for NO_FORECAST',
        defaultMessage:
            'No avalanche forecast is available for this region. This location is outside of our forecast area or we are not forecasting for this area at this time. If you plan to travel on snow-covered slopes without an avalanche forecast, be sure you are prepared to evaluate and manage avalanche risk independently.',
    },
    [Ratings.SPRING]: {
        description: 'Travel Advice message for SPRING',
        defaultMessage:
            'Melt-freeze conditions are common. Expect danger to be lower late at night and in the morning, followed by higher danger in the afternoon and evening. Danger is low when surface snow is frozen, but can rapidly increase to high as temperatures rise and the surface crust breaks down.',
    },
    [Ratings.EARLY_SEASON]: {
        description: 'Travel Advice message for EARLY_SEASON',
        defaultMessage:
            'Insufficient observations exist to reliably rate the avalanche danger. Expect shallow snow cover with buried rocks, trees, and stumps hidden just beneath the surface. Avalanche danger will be most likely in gullies and other areas where wind has deposited snow and created slabs that are often poorly bonded to a weak base.',
    },
})

export const LikehoodOfAvalanche = defineMessages({
    [Ratings.EXTREME]: {
        description: 'Likelihood of Avalanche message for EXTREME',
        defaultMessage: 'Natural and human-triggered avalanches certain.',
    },
    [Ratings.HIGH]: {
        description: 'Likelihood of Avalanche message for HIGH',
        defaultMessage: 'Natural avalanches likely; human-triggered avalanches very likely.',
    },
    [Ratings.CONSIDERABLE]: {
        description: 'Likelihood of Avalanche message for CONSIDERABLE',
        defaultMessage: 'Natural avalanches possible; human-triggered avalanches likely.',
    },
    [Ratings.MODERATE]: {
        description: 'Likelihood of Avalanche message for MODERATE',
        defaultMessage: 'Natural avalanches unlikely; human-triggered avalanches possible.',
    },
    [Ratings.LOW]: {
        description: 'Likelihood of Avalanche message for LOW',
        defaultMessage: 'Natural and human-triggered avalanches unlikely.',
    },
})

export const SizeAndDistribution = defineMessages({
    [Ratings.EXTREME]: {
        description: 'Size and Distribution message for EXTREME',
        defaultMessage: 'Very large avalanches in many areas.',
    },
    [Ratings.HIGH]: {
        description: 'Size and Distribution message for HIGH',
        defaultMessage: 'Large avalanches in many areas; or very large avalanches in specific areas.',
    },
    [Ratings.CONSIDERABLE]: {
        description: 'Size and Distribution message for CONSIDERABLE',
        defaultMessage:
            'Small avalanches in many areas; or large avalanches in specific areas; or very large avalanches in isolated areas.',
    },
    [Ratings.MODERATE]: {
        description: 'Size and Distribution message for MODERATE',
        defaultMessage: 'Small avalanches in specific areas; or large avalanches in isolated areas.',
    },
    [Ratings.LOW]: {
        description: 'Size and Distribution message for LOW',
        defaultMessage: 'Small avalanches in isolated areas or extreme terrain.',
    },
})

export const Names = defineMessages({
    [Ratings.EXTREME]: {
        description: 'Ratings Texts for EXTREME',
        defaultMessage: 'Extreme',
    },
    [Ratings.HIGH]: {
        description: 'Ratings Texts for HIGH',
        defaultMessage: 'High',
    },
    [Ratings.CONSIDERABLE]: {
        description: 'Ratings Texts for CONSIDERABLE',
        defaultMessage: 'Considerable',
    },
    [Ratings.MODERATE]: {
        description: 'Ratings Texts for MODERATE',
        defaultMessage: 'Moderate',
    },
    [Ratings.LOW]: {
        description: 'Ratings Texts for LOW',
        defaultMessage: 'Low',
    },
    [Ratings.NO_RATING]: {
        description: 'Ratings Texts for NO_RATING',
        defaultMessage: 'No Rating',
    },
    [Ratings.NO_FORECAST]: {
        description: 'Ratings Texts for NO_FORECAST',
        defaultMessage: 'No Forecast',
    },
    [Ratings.SPRING]: {
        description: 'Ratings Texts for SPRING',
        defaultMessage: 'Spring',
    },
    [Ratings.EARLY_SEASON]: {
        description: 'Ratings Texts for EARLY_SEASON',
        defaultMessage: 'Early Season',
    },
})

export const NamesWithNumber = defineMessages({
    [Ratings.EXTREME]: {
        description: 'Ratings Texts for EXTREME',
        defaultMessage: '5 - Extreme',
    },
    [Ratings.HIGH]: {
        description: 'Ratings Texts for HIGH',
        defaultMessage: '4 - High',
    },
    [Ratings.CONSIDERABLE]: {
        description: 'Ratings Texts for CONSIDERABLE',
        defaultMessage: '3 - Considerable',
    },
    [Ratings.MODERATE]: {
        description: 'Ratings Texts for MODERATE',
        defaultMessage: '2 - Moderate',
    },
    [Ratings.LOW]: {
        description: 'Ratings Texts for LOW',
        defaultMessage: '1 - Low',
    },
    [Ratings.NO_RATING]: {
        description: 'Ratings Texts for NO_RATING',
        defaultMessage: '0 - No Rating',
    },
    [Ratings.NO_FORECAST]: {
        description: 'Ratings Texts for NO_FORECAST',
        defaultMessage: '0 - No Forecast',
    },
    [Ratings.SPRING]: {
        description: 'Ratings Texts for SPRING',
        defaultMessage: '0 - Spring',
    },
    [Ratings.EARLY_SEASON]: {
        description: 'Ratings Texts for EARLY_SEASON',
        defaultMessage: '0 - Early Season',
    },
})
