import { ReactNode } from 'react'

import clsx from 'clsx'
import { Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import COLOR_PALETTE, { COLOUR_BLIND_PALETTE } from '@avalanche-canada/constants/products/forecast/ratings/colors'
import { EXTREME, NO_RATING } from '@avalanche-canada/constants/products/forecast/ratings'

import { LikehoodOfAvalanche, TravelAdvices, SizeAndDistribution } from 'constants/products/forecast/messages'
import { IconByRating } from 'components/graphics/danger/Banner'
import { DangerRating } from 'hooks/useDangerRatings'
import { useColorBlindMode } from 'stores/SettingsStore'

import styles from './DangerRatingTable.module.css'

type RowProps = {
    row: DangerRating
}

export const Row = ({ row }: RowProps) => {
    const hasLikelihood = row.rating in LikehoodOfAvalanche
    const hasSizeAndDistribution = row.rating in SizeAndDistribution
    const { colourblindModeEnabled } = useColorBlindMode()
    const palette = colourblindModeEnabled ? COLOUR_BLIND_PALETTE : COLOR_PALETTE
    const Icon = IconByRating.get(row.rating)

    return (
        <Box className={clsx(styles.Row, !hasLikelihood && !hasSizeAndDistribution && styles.SpanColumns)}>
            <Box
                className={styles.Swatch}
                style={{
                    backgroundColor: palette.get(row.rating),
                    border: row.rating === NO_RATING || row.rating === EXTREME ? '1px solid var(--text-color)' : 'none',
                }}
            ></Box>
            <Box className={styles.DangerLevelCell}>
                <h3>{row.heading}</h3>
                <svg width={80} height={55} viewBox="0 0 120 82">
                    {Icon && <Icon />}
                </svg>
            </Box>
            <Box className={clsx(styles.Cell, !hasLikelihood && !hasSizeAndDistribution && styles.SpanColumns)}>
                {TravelAdvices[row.rating] && (
                    <Box className={styles.CellContent}>
                        <FormattedMessage
                            {...TravelAdvices[row.rating as keyof typeof TravelAdvices]}
                            values={
                                {
                                    b: (chunks: ReactNode) => <strong>{chunks}</strong>,
                                } as unknown as Record<string, ReactNode>
                            }
                        />
                    </Box>
                )}
            </Box>
            {hasLikelihood && (
                <Box className={styles.Cell}>
                    <Box className={styles.CellContent}>
                        <FormattedMessage {...LikehoodOfAvalanche[row.rating as keyof typeof LikehoodOfAvalanche]} />
                    </Box>
                </Box>
            )}
            {hasSizeAndDistribution && (
                <Box className={styles.Cell}>
                    <Box className={styles.CellContent}>
                        <FormattedMessage {...SizeAndDistribution[row.rating as keyof typeof SizeAndDistribution]} />
                    </Box>
                </Box>
            )}
        </Box>
    )
}
