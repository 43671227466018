import * as React from 'react'

import Forecasts from 'clients/forecasts'

export function Provider({ product, children }) {
    const value = React.useMemo(createValue, [product])
    function createValue() {
        return {
            product,
        }
    }

    return <context.Provider value={value}>{children}</context.Provider>
}

export async function get(id, locale) {
    const product = await Forecasts.product(id, locale)

    return { product }
}

// Hook: aka getters
export function useForecast() {
    return useContextSlice('product')
}

export function useReport() {
    const forecast = useForecast()

    return forecast?.report
}

export function useMedia() {
    const forecast = useForecast()

    return forecast?.media
}

export function useContext() {
    return React.useContext(context)
}

function useContextSlice(name) {
    return useContext()[name]
}

const context = React.createContext()
