import * as React from 'react'
import { useIntl } from 'react-intl'

import {
    EARLY_SEASON,
    SPRING,
    NO_RATING,
    LOW,
    MODERATE,
    CONSIDERABLE,
    HIGH,
    EXTREME,
} from '@avalanche-canada/constants/products/forecast/ratings'
import { TravelAdvices } from 'constants/products/forecast/messages'

type Rating = keyof typeof TravelAdvices

export type DangerRating = {
    rating: Rating
    heading: React.ReactNode
    render: boolean
}

export const useTableDangerRatings = (): DangerRating[] => {
    const intl = useIntl()

    return [
        {
            rating: EXTREME,
            heading: intl.formatMessage({
                defaultMessage: '5 - Extreme',
                description: 'Map legend',
            }),
            render: true,
        },
        {
            rating: HIGH,
            heading: intl.formatMessage({
                defaultMessage: '4 - High',
                description: 'Map legend',
            }),
            render: true,
        },

        {
            rating: CONSIDERABLE,
            heading: intl.formatMessage({
                defaultMessage: '3 - Considerable',
                description: 'Map legend',
            }),
            render: true,
        },
        {
            rating: MODERATE,
            heading: intl.formatMessage({
                defaultMessage: '2 - Moderate',
                description: 'Map legend',
            }),
            render: true,
        },
        {
            rating: LOW,
            heading: intl.formatMessage({
                defaultMessage: '1 - Low',
                description: 'Map legend',
            }),
            render: true,
        },
        {
            rating: NO_RATING,
            heading: intl.formatMessage({
                defaultMessage: 'No Rating',
                description: 'Map legend',
            }),
            render: true,
        },
        {
            rating: EARLY_SEASON,
            heading: intl.formatMessage({
                defaultMessage: 'Early Season',
                description: 'Map legend',
            }),
            render: true,
        },
        {
            rating: SPRING,
            heading: intl.formatMessage({
                defaultMessage: 'Spring',
                description: 'Map legend',
            }),
            render: true,
        },
    ]
}
